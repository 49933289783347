import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PrimengExportsModule } from '../primeng-exports.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  standalone: true,
  imports: [PrimengExportsModule, CommonModule]
})
export class ErrorPageComponent implements OnInit {
  errorDetail!: string;
  errorStatus!: string;
  errorHeader!: string;
  dialogData!: any;
  buttonLabel!: string;
  constructor(
    private route: ActivatedRoute,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.initializeErrorContent();
    this.initializeDialogProperties();
  }

  initializeDialogProperties() {
    this.dialogData = this.dialogConfig.data;
    this.dialogConfig.closable = false;
    if (this.dialogData) {
      this.errorDetail = `Error ${this.dialogData.status}: `;
      this.errorDetail +=
        Array.isArray(this.dialogData.error?.detail) &&
        this.dialogData.error?.detail?.length > 0
          ? this.dialogData.error?.detail[0]?.msg
          : this.dialogData.error?.detail || 'Unknown error';
      this.buttonLabel = 'Close';
    } else {
      this.buttonLabel = 'Logout';
    }
  }

  initializeErrorContent() {
    this.route.queryParams.subscribe((error) => {
      this.errorStatus = error['status'];
      this.errorDetail =
        error['status'] === 404
          ? "We can't seem to find the page you are looking for. Don't worry, you can return to browsing by clicking on the navigation bar"
          : error['detail'];
      this.errorHeader =
        error['status'] === 404
          ? 'OOPS! PAGE NOT FOUND.'
          : 'Something went wrong.';
    });
  }

  onClick() {
    if (this.dialogData) {
      this.dialogRef.close();
      return;
    }

    // eslint-disable-next-line no-console
    console.log('logout event');
  }
}
