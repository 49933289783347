<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  appFileDnd
  (fileDropped)="onFileDropped($event)"
  class="file-upload-control flex flex-column align-items-center justify-content-center mb-2"
  (click)="onFileSelect()"
  [ngClass]="disableUpload ? 'disable-upload' : ''"
>
  <p-button [disabled]="disableUpload" class="mb-3"
    ><span class="material-symbols-outlined mr-2"> cloud_upload </span
    >{{ 'DASHBOARD.UPLOAD_MODAL.BROWSE_FILE' | transloco }}</p-button
  >
  <span
    >{{ 'DASHBOARD.UPLOAD_MODAL.DRAG_AND_DROP_A_FILE_HERE' | transloco }}
  </span>
</div>
<div
  *ngIf="customErrorMessage || errorMessage as error"
  class="error-section mb-3"
>
  <span>{{ error | transloco }}{{ errorMessageTemplate | transloco }}</span>
</div>

<div *ngIf="uploadedFileList.length" class="uploaded-file-container">
  <div
    *ngFor="let file of uploadedFileList; index as i"
    class="upload-file-item flex justify-content-between align-items-center p-4 my-2"
  >
    <div class="flex align-items-center w-full">
      <span class="material-symbols-outlined mr-6 file-icon">
        upload_file
      </span>
      <div class="flex justify-content-between flex-column h-3rem">
        <span class="font-normal">{{ file.name }}</span>
        <div
          class="file-size-label flex w-8rem justify-content-between align-items-center"
        >
          <span>{{ file.size | fileSize }}</span>
          <!-- <i class="pi pi-circle-fill" style="font-size: 0.25rem"></i>
          <span> status</span> -->
        </div>
      </div>
    </div>
    <button
      pButton
      pRipple
      type="button"
      (click)="onFileDelete(i)"
      class="p-button-text table-action"
    >
      <span class="material-symbols-outlined delete-icon text-4xl">
        delete
      </span>
    </button>
  </div>
</div>

<input
  #inputFile
  type="file"
  name="inputFile"
  id="inputFile"
  (click)="$any($event.target).value = null"
  (change)="onFileSelected($event)"
  hidden="true"
  [accept]="formattedAcceptedFileTypes"
  [multiple]="multiple"
/>
