<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  class="container flex flex-column justify-content-center align-items-center"
>
  <span class="mb-2 text-xl font-semibold">{{
    'DASHBOARD.UPLOAD_MODAL.UPLOAD_A_FILE' | transloco
  }}</span>
  <span class="mb-2">{{
    'DASHBOARD.UPLOAD_MODAL.ACCEPTED_FILE_TYPE_CSV_XLSX' | transloco
  }}</span>
  <span class="mb-3">{{
    'DASHBOARD.UPLOAD_MODAL.MAX_SIZE_10_MB' | transloco
  }}</span>

  <div class="flex w-full justify-content-center">
    <a
      href="https://agridence.github.io/public-docs/traceability/file_uploads/"
      target="_blank"
      >{{ 'DASHBOARD.UPLOAD_MODAL.LEARN_MORE' | transloco }}</a
    >
    <a class="ml-6" (click)="op.toggle($event)">{{
      'DASHBOARD.UPLOAD_MODAL.DOWNLOAD_REFERENCE' | transloco
    }}</a>
  </div>
  <p-overlayPanel #op>
    <div class="flex flex-column download-options">
      <div *ngFor="let template of downloadTemplateOptions">
        <div
          (click)="downloadTemplate(template)"
          class="flex align-items-center download-option"
        >
          <span> {{ template.label }}</span>
        </div>
      </div>
    </div>
  </p-overlayPanel>

  <div class="flex flex-column w-full mt-4">
    <app-upload-file-control
      [customErrorMessage]="errorMessage"
      (valid)="onCheckFileValid($event)"
      (filesSelected)="onFilesSelected($event)"
      (fileType)="getFileType($event)"
      [parse]="true"
      [parsedFile]="uploadedFile"
    />

    <div class="flex justify-content-end mt-3 w-full">
      <p-button
        (onClick)="onActionClicked()"
        [disabled]="hasParsingError || !isFileValid || !hasFile"
      >
        {{ actionLabel | transloco }}</p-button
      >
    </div>
  </div>
</div>
