import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDndDirective } from 'src/app/directives/file-dnd.directive';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { FileSizePipe } from 'src/pipes/file-size.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { FileTypesEnum } from 'src/app/enums/file-types.enum';

@Component({
  selector: 'app-upload-file-control',
  standalone: true,
  imports: [
    CommonModule,
    FileDndDirective,
    PrimengExportsModule,
    FileSizePipe,
    TranslocoPipe
  ],
  templateUrl: './upload-file-control.component.html',
  styleUrls: ['./upload-file-control.component.scss']
})
export class UploadFileControlComponent implements OnInit, OnChanges {
  @ViewChild('inputFile') inputFile!: ElementRef;

  @Input() acceptedFileTypes: string[] = [];
  @Input() multiple = false;
  @Input() customErrorMessage = '';
  @Input() fileSizeLimit = 10 * 1024 * 1024; // 10 MB
  // used for upload with parsing custom validations
  @Input() parse = false;
  @Input() parsedFile!: File | null;

  @Output() filesSelected = new EventEmitter<File[] | null>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() fileType = new EventEmitter<FileTypesEnum | null>();
  hasError = false;
  xlsxExtensions = ['.csv', '.xls', '.xlsx'];
  defaultAcceptedFileTypes = ['.csv', '.xls', '.xlsx', '.json', '.geojson'];
  formattedAcceptedFileTypes = '';

  uploadedFileList: File[] = [];
  errorMessageTemplate = 'DASHBOARD.UPLOAD_MODAL.PLEASE_REUPLOAD_YOUR_FILE';
  errorMessage = '';

  get disableUpload() {
    return !this.multiple && this.uploadedFileList.length === 1;
  }

  ngOnInit(): void {
    this.getFormattedAcceptedFileTypes();
  }

  ngOnChanges(): void {
    // TODO: update to handle multiple parsing
    if (this.parse && !this.customErrorMessage && this.parsedFile) {
      this.uploadedFileList.push(this.parsedFile);
    }

    if (this.parse && this.customErrorMessage) {
      this.hasError = true;
    }
  }

  getFormattedAcceptedFileTypes() {
    if (this.acceptedFileTypes.length > 0) {
      this.defaultAcceptedFileTypes = [...this.acceptedFileTypes];
    }
    this.formattedAcceptedFileTypes = this.defaultAcceptedFileTypes.join(',');
  }

  fileExtensionValidation(file: File) {
    const fileExtension = this.getFileExtension(file.name);
    if (fileExtension) {
      if (this.xlsxExtensions.includes(fileExtension)) {
        this.fileType.emit(FileTypesEnum.EXCEL);
      } else {
        // TODO: include other file types
        this.fileType.emit(null);
      }
      if (!this.defaultAcceptedFileTypes.includes(fileExtension)) {
        this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.WRONG_FORMAT';
        return false;
      }
      return true;
    }
    return false;
  }

  fileSizeValidation(file: File) {
    const maxFileSizeInBytes = this.fileSizeLimit;
    if (file.size > maxFileSizeInBytes) {
      this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.FILE_SIZE_EXCEEDED';
      return false;
    }
    return true;
  }

  fileLimitValidation(file: File) {
    // if (file.length > 1) {
    this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.UPLOAD_1_FILE_ONLY';
    return false;
    // }
    return true;
  }

  getFileExtension(filename: string): string {
    const dotIndex = filename.lastIndexOf('.');
    if (dotIndex === -1) {
      return '';
    }

    const extension = filename.slice(dotIndex + 1);
    return `.${extension.toLowerCase()}`;
  }

  onFileDropped(file: File[]) {
    this.onFileSelected(file, true);
  }

  onFileSelect() {
    if (this.disableUpload) {
      return;
    }
    this.inputFile.nativeElement.click();
  }

  onFileDelete(index: number) {
    this.uploadedFileList.splice(index, 1);
    this.filesSelected.emit(null);
  }

  onFileSelected(event: any, isFromFileDrop = false) {
    if (this.disableUpload) {
      return;
    }
    const files: File[] = isFromFileDrop
      ? Array.from(event)
      : Array.from(event.target.files);
    if (files) {
      this.uploadValidation(files);
    }
  }

  uploadValidation(files: File[]) {
    files.forEach((file) => {
      if (
        !(this.fileExtensionValidation(file) && this.fileSizeValidation(file))
      ) {
        this.hasError = true;
        this.valid.emit(false);
      } else {
        this.hasError = false;
        if (!this.parse && file) {
          this.uploadedFileList.push(file);
        }
      }
    });

    this.valid.emit(!this.hasError);
    this.filesSelected.emit(files);
  }
}
