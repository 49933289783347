import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrimengExportsModule } from '../primeng-exports.module';
import { FormsModule } from '@angular/forms';
import { PlantationTableComponent } from './plantation-table/plantation-table.component';
import { RefreshButtonComponent } from '../shared/refresh-button/refresh-button.component';
import { Subject, takeUntil } from 'rxjs';
import { CrdStateService } from '../services/state-service/crd-state.service';
import {
  Dataset,
  INITIAL_SELECTED_PERIOD
} from '../models/crd-state.interface';
import { EventStateService } from '../services/state-service/event-state.service';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { UtilityService } from '../services/utility.service';
import { SettingsModalHeadersEnum } from '../enums/settings-modal-headers.enum';
import { ScreenEnum } from '../enums/screens.enum';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { SummaryChartsComponent } from './summary-charts/summary-charts.component';
import { PlantationTableTabsEnum } from '../enums/plantation-table-tabs.enum';
import { AutoComplete } from 'primeng/autocomplete';
import { DatasetAutocompleteComponent } from '../shared/dataset-autocomplete/dataset-autocomplete.component';

@Component({
  selector: 'app-summary-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    PlantationTableComponent,
    RefreshButtonComponent,
    PageHeaderComponent,
    TranslocoPipe,
    SummaryChartsComponent,
    DatasetAutocompleteComponent
  ],
  templateUrl: './summary-dashboard.component.html',
  styleUrls: ['./summary-dashboard.component.scss']
})
export class SummaryDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('datasetAutocomplete') datasetAutocomplete!: AutoComplete;
  lastUpdatedDate!: string;

  selectedPeriod = INITIAL_SELECTED_PERIOD;

  datasetOptions!: Dataset[];
  selectedDataset!: Dataset;
  previousSelectedDataset!: Dataset;
  filteredDatasetOptions!: Dataset[];
  destroyed$ = new Subject<void>();

  selectedTab$ = this.crdStateService.selectedTab$;
  PLANTATION_TAB = PlantationTableTabsEnum.PLANTATION;
  QUESTIONNAIRE_TAB = PlantationTableTabsEnum.QUESTIONNAIRE;

  subHeader = this.translocoService.translate('DASHBOARD.SUMMARY');

  constructor(
    public crdStateService: CrdStateService,
    public eventStateService: EventStateService,
    private datePipe: DatePipe,
    private utilityService: UtilityService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    // TODO: uncomment on integration
    // this.checkConfiguredSettings();
    this.initializeDatasets();
  }

  ngOnDestroy(): void {
    this.crdStateService.resetDashboardStates();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  checkConfiguredSettings() {
    // TODO: add checking on integration
    this.utilityService.openSettingsModal(
      SettingsModalHeadersEnum.REDIRECT,
      ScreenEnum.DASHBOARD
    );
  }

  getPeriodValue(lastNMonths: number) {
    const today = new Date();
    const month = new Date(today).setMonth(today.getMonth() - lastNMonths);

    return this.datePipe.transform(month, 'yyyy-MM-dd');
  }

  onSelectedPeriodChanged(date: string) {
    this.crdStateService.setSelectedPeriod(
      this.datePipe.transform(date, 'yyyy-MM-dd')!
    );
    this.crdStateService.updateDataset();
    this.crdStateService.setSelectedTab(PlantationTableTabsEnum.PLANTATION);
  }

  initializeDatasets() {
    this.crdStateService.selectedPeriod$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((selectedPeriod) => {
        if (selectedPeriod) {
          this.selectedPeriod = selectedPeriod;
        }
      });
  }

  translateLastNMonths(value: number) {
    return this.translocoService.translate('DASHBOARD.LAST_N_MONTHS', {
      value: value
    });
  }
}
