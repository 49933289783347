/* eslint-disable no-prototype-builtins */
import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileDndDirective } from 'src/app/directives/file-dnd.directive';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { FileSizePipe } from 'src/pipes/file-size.pipe';
import * as XLSX from 'xlsx';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import { REQUIRED_COLUMNS } from 'src/app/constants/required-columns.const';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { isGeoJSONObject } from 'geojson-validation';
import { GeoJSONGeometry, stringify } from 'wellknown';
import { GeoJsonModel } from 'src/app/models/geojson.model';
import { ParsedUploadPlantation } from 'src/app/models/parsed-upload-plantation.model';
import saveAs from 'file-saver';
import { DownloadTemplateOption } from 'src/app/models/download-template-option.model';
import { UploadFileControlComponent } from 'src/app/shared/upload-file-control/upload-file-control.component';
import { FileTypesEnum } from 'src/app/enums/file-types.enum';

@Component({
  selector: 'app-import-tab',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FileDndDirective,
    FileSizePipe,
    TranslocoPipe,
    UploadFileControlComponent
  ],
  templateUrl: './import-tab.component.html',
  styleUrls: ['./import-tab.component.scss']
})
export class ImportTabComponent {
  @Output() isFileInvalid = new EventEmitter<boolean>();
  @Output() nextClicked = new EventEmitter<void>();
  @ViewChild('inputFile') inputFile!: ElementRef;
  uploadedFile!: File | null;
  actionLabel = 'DASHBOARD.UPLOAD_MODAL.CONTINUE_TO_ORGANIZE';
  errorMessageTemplate = 'DASHBOARD.UPLOAD_MODAL.PLEASE_REUPLOAD_YOUR_FILE';
  errorMessage = '';
  hasParsingError = false;
  downloadTemplateOptions: DownloadTemplateOption[] = [
    {
      label: this.translocoService.translate(
        'DASHBOARD.UPLOAD_MODAL.EXCEL_TEMPLATE'
      ),
      fileName: 'Plantation Data Upload Template.xlsx',
      filePath:
        'https://assets.agridence.com/docs-assets/traceability/plantation-data-upload%20template.xlsx'
    },
    {
      label: this.translocoService.translate(
        'DASHBOARD.UPLOAD_MODAL.JSON_TEMPLATE'
      ),
      fileName: 'sample-geojson',
      filePath:
        'https://assets.agridence.com/docs-assets/traceability/sample-geojson.json'
    }
  ];

  isFileValid = false;
  hasFile = false;
  fileType: FileTypesEnum | null = null;

  constructor(
    private dialogRef: DynamicDialogRef,
    private crdStateService: CrdStateService,
    private datePipe: DatePipe,
    private translocoService: TranslocoService
  ) {}

  onBackClicked() {
    this.dialogRef.close();
  }

  uploadValidation(file: File) {
    if (this.isFileValid) {
      this.parseUploadedFile(file);
    }
  }

  onActionClicked() {
    if (!this.isFileValid || this.hasParsingError) {
      this.isFileValid = true;
      this.hasParsingError = false;
      this.uploadedFile = null;
      return;
    }
    this.nextClicked.emit();
    this.isFileInvalid.emit(!this.isFileValid || this.hasParsingError);
  }

  parseUploadedFile(file: File) {
    const fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      try {
        let data = e.target.result;
        data = data.replace(/^\xEF\xBB\xBF/, '');

        if (this.fileType === FileTypesEnum.EXCEL) {
          this.parseXlsx(data);
        } else {
          this.parseJson(data);
        }
        if (!this.hasParsingError) {
          this.uploadedFile = file;
        } else {
          this.uploadedFile = null;
        }
      } catch (error) {
        console.error(error);
        // will only go here if json file is invalid - missing brackets, commas, etc.
        this.hasParsingError = true;
        this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.INVALID_JSON_FILE';
      }
    };
    fileReader.readAsBinaryString(file);
  }

  parseJson(data: any) {
    const parsedData: GeoJsonModel = JSON.parse(data);
    const isValidGeoJson = isGeoJSONObject(parsedData);

    const hasRequiredProperties = parsedData?.features?.every(
      ({ properties }) => properties?.ProductionPlace && properties?.Area
    );

    parsedData?.features?.forEach((feature, index) => {
      if (!isGeoJSONObject(feature)) {
        console.error(
          `invalid geojson - index: ${index}  production place:  ${feature.properties?.ProductionPlace} `
        );
      }

      if (!feature.properties?.ProductionPlace && !feature.properties?.Area) {
        console.error(
          `missing required properties` +
            `- index: ${index} production place: ${feature.properties?.ProductionPlace} `
        );
      }
    });

    if (!isValidGeoJson || !hasRequiredProperties) {
      this.hasParsingError = true;
      this.errorMessage = !isValidGeoJson
        ? 'Invalid geojson'
        : 'Missing required property in geojson';
    } else {
      const mappedData: ParsedUploadPlantation[] = parsedData.features.map(
        ({ properties, geometry }, index: number) => ({
          id: ++index,
          plantation_code:
            properties?.PlantationCode || properties?.ProductionPlace,
          plantation_name: properties?.ProductionPlace,
          geometry: stringify(geometry as GeoJSONGeometry),
          date_created:
            properties?.DateCreated ||
            this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
          error: null,
          land_area: +properties?.Area
        })
      );
      this.hasParsingError = false;
      this.errorMessage = '';
      this.crdStateService.setParsedFileData(mappedData);
      this.actionLabel = 'DASHBOARD.UPLOAD_MODAL.CONTINUE_TO_ORGANIZE';
    }
  }

  parseXlsx(data: any) {
    const jsonOpts = {
      defval: '',
      blankrows: true,
      raw: false,
      dateNF: 'd"/"m"/"yyyy'
    };
    const workbook = XLSX.read(data, {
      type: 'binary'
    });
    workbook.SheetNames.forEach((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils
        .sheet_to_json(worksheet, jsonOpts)
        .filter(
          (data: any) =>
            data['Plantation Code'] ||
            data['Plantation Name'] ||
            data['Geometry'] ||
            data['Date Created'] ||
            data['Land Area (ha)']
        );
      if (this.isHeadersValid(parsedData)) {
        const mappedData: ParsedUploadPlantation[] = parsedData.map(
          (data: any, index: number) => {
            return {
              id: ++index,
              plantation_code: data['Plantation Code'] || null,
              plantation_name: data['Plantation Name'] || null,
              geometry: data['Geometry'] || null,
              date_created: data['Date Created']?.trim() || null,
              error: null,
              land_area: Number(data['Land Area (ha)']) || null
            };
          }
        );
        this.hasParsingError = false;
        this.errorMessage = '';
        this.actionLabel = 'DASHBOARD.UPLOAD_MODAL.CONTINUE_TO_ORGANIZE';
        this.crdStateService.setParsedFileData(mappedData);
      } else {
        this.hasParsingError = true;
        this.errorMessage = 'DASHBOARD.UPLOAD_MODAL.INVALID_FILE_HEADERS';
      }
    });
  }

  isHeadersValid(data: any[]) {
    if (!data.length) {
      return false;
    }

    for (let i = 0; i < data.length; i++) {
      const valid = REQUIRED_COLUMNS.every((prop) =>
        data[i].hasOwnProperty(prop)
      );
      if (!valid) {
        return false;
      }
    }

    return true;
  }

  downloadTemplate(template: DownloadTemplateOption): void {
    saveAs(template.filePath, template.fileName);
  }

  onFilesSelected(files: File[] | null) {
    this.uploadedFile = null;
    this.hasFile = !!files?.length;
    if (this.hasFile) {
      this.uploadValidation(files![0]);
    }
  }

  onCheckFileValid(isValid: boolean) {
    this.isFileValid = isValid;
  }

  getFileType(fileType: FileTypesEnum | null) {
    this.fileType = fileType;
  }
}
