<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div>
  <p-card *ngIf="!isTableLoading; else geometryDataLoading">
    <ng-template *ngTemplateOutlet="siDetails" />
    <ng-template *ngTemplateOutlet="summaryTable" />
  </p-card>
</div>

<ng-template #siDetails>
  <div class="font-semibold flex flex-column my-3">
    <span class="mb-1">
      {{ 'DASHBOARD.SI_LINKING.CONTRACT_NUMBER' | transloco }}:
      {{ (crdStateService.siDetails$ | async)?.contractNumber }}</span
    >
    <span class="mb-1"
      >{{ (crdStateService.siDetails$ | async)?.company?.type | transloco }}:
      {{ (crdStateService.siDetails$ | async)?.company?.name }}</span
    >
    <span class="mb-1">
      {{ 'DASHBOARD.SI_LINKING.FACTORY_NAME' | transloco }}:
      {{ (crdStateService.siDetails$ | async)?.factoryCode }}</span
    >
    <span class="mb-1">
      {{ 'DASHBOARD.SI_LINKING.FACTORY_SYMBOL' | transloco }}:
      {{ (crdStateService.siDetails$ | async)?.factoryCode }}</span
    >
  </div>
</ng-template>

<!-- TODO: extract table to reusable component -->
<ng-template #summaryTable>
  <div class="summary-table">
    <p-table
      [value]="selectedItems"
      [rows]="pageSize"
      [columns]="columns"
      [dataKey]="tableDataKey"
      [tableStyle]="{ 'min-width': '130rem' }"
      [paginator]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{{
        'DASHBOARD.SHOWING_TABLE_ENTRIES_COUNT'
          | transloco
            : {
                first: '{first}',
                last: '{last}',
                totalRecords: '{totalRecords}'
              }
      }}"
      [rowsPerPageOptions]="[10, 30, 100, 1000]"
    >
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngFor="let column of columns; index as i">
            <th
              [pSortableColumn]="column.sortable ? column.field : ''"
              [style]="column?.style"
            >
              {{ column.displayName | transloco }}
              <p-sortIcon
                *ngIf="column.sortable"
                [field]="column.field"
              ></p-sortIcon>
              <p-columnFilter
                *ngIf="column.filter && column.field !== 'risks'"
                [field]="column.field"
                [matchMode]="column.field === 'risks' ? 'risk-filter' : 'in'"
                display="menu"
                [showMatchModes]="false"
                [showOperator]="false"
                [showAddButton]="false"
              >
                <ng-template
                  pTemplate="filter"
                  let-value
                  let-filter="filterCallback"
                >
                  <p-multiSelect
                    [ngModel]="value"
                    [options]="getFilterOptions(column.field)"
                    placeholder="{{ 'DASHBOARD.ANY' | transloco }}"
                    (onChange)="filter($event.value)"
                    optionLabel="label"
                    optionValue="value"
                  >
                    <ng-template let-option pTemplate="item">
                      <div class="flex">
                        <app-risk-icon
                          *ngIf="option?.hasRiskIcon"
                          [riskType]="option.value"
                        ></app-risk-icon>
                        <span class="ml-1 mt-1">{{ option.label || '-' }}</span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-selectedItems let-index="rowIndex">
        <tr>
          <ng-container *ngFor="let column of columns; index as i">
            <td>
              <ng-container *ngIf="column.field === 'risks'; else text">
                <ng-container
                  *ngIf="
                    selectedItems['risks']?.length &&
                      selectedItems['risks'][0] !== riskTypes.NO_RISKS;
                    else noRisk
                  "
                >
                  <ng-container *ngFor="let risk of selectedItems['risks']">
                    <app-risk-icon
                      [iconColor]="risk?.color"
                      [label]="risk?.display_name"
                      [marginClass]="'mr-2'"
                    ></app-risk-icon>
                  </ng-container>
                </ng-container>
                <ng-template #noRisk><span>-</span></ng-template>
              </ng-container>
              <ng-template #text>
                <div class="flex align-items-center">
                  <span
                    *ngIf="
                      !(
                        column.field === 'area_calculated' ||
                        column.field === 'plantation_name'
                      )
                    "
                    >{{ selectedItems[column.field] || '-' }}</span
                  >
                  <span *ngIf="column.field === 'area_calculated'"
                    >{{ selectedItems[column.field] || '-' }}
                    <span *ngIf="selectedItems[column.field]">ha</span></span
                  >
                  <ng-container *ngIf="column.field === 'plantation_name'">
                    <!-- TODO: remove getGeoJsonTypeProps from template, assign in data service -->
                    <span
                      [pTooltip]="
                        utilityService.getGeoJsonTypeProps(
                          selectedItems['_geo_type']
                        ).label
                      "
                      tooltipPosition="bottom"
                      class="material-symbols-outlined mr-1 geojson-type-icon"
                    >
                      {{
                        utilityService.getGeoJsonTypeProps(
                          selectedItems['_geo_type']
                        ).icon
                      }}
                    </span>
                    <span>{{ selectedItems[column.field] || '-' }}</span>
                  </ng-container>
                </div>
              </ng-template>
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>

<ng-template #geometryDataLoading>
  <div>
    <p-skeleton width="100%" height="40rem"></p-skeleton>
  </div>
</ng-template>
