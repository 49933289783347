<div class="modal-container flex flex-column h-full">
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="1">
      <div class="pb-8">
        <ng-template *ngTemplateOutlet="step1" />
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <ng-template *ngTemplateOutlet="step2" />
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <ng-template *ngTemplateOutlet="step3" />
    </ng-container>
  </ng-container>
</div>

<ng-template #step1>
  <div class="flex flex-column mx-4 my-3">
    <span class="mt-3 font-semibold"
      >{{ selectedRisksStep1.length }}/{{ rowData.length }} risk verification
      done</span
    >
    <span class="font-semibold"
      >Below are the list of risks that needs to be verified:</span
    >
  </div>
  <div
    class="selected-risk-count-container flex justify-content-between align-items-center mx-4 p-4 mb-3"
  >
    <span>{{ selectedRisksStep1.length }} selected</span>
    <div class="flex align-items-center">
      <p-button (onClick)="onClearAll()" styleClass="p-button-text"
        >Clear all</p-button
      >
      <p-button
        [disabled]="!selectedRisksStep1.length"
        (onClick)="onProceedRiskVerification()"
        styleClass="mx-4"
        >Proceed Risk Verification</p-button
      >
    </div>
  </div>

  <div class="mx-4">
    <p-table
      [columns]="columns"
      dataKey="id"
      [value]="rowData"
      [(selection)]="selectedRisksStep1"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="checkbox-cell">
            <p-tableHeaderCheckbox #headerCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let column of columns; index as i">
            <th [style]="column?.style">
              {{ column.displayName | transloco }}
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-editing="editing"
        let-index="rowIndex"
      >
        <tr>
          <td class="checkbox-cell">
            <p-checkbox
              [value]="rowData"
              name="submissions"
              [(ngModel)]="selectedRisksStep1"
            ></p-checkbox>
          </td>
          <ng-container *ngFor="let column of columns">
            <td>{{ rowData[column.field] }}</td>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-template>

<ng-template #step2>
  <div class="flex flex-column mx-4 my-3">
    <span class="mt-3 font-semibold">Accepted file type: JPEG, JPG</span>
    <span class="font-semibold">Max size: 5 MB</span>
  </div>
  <div class="flex flex-column mx-4 mb-3">
    <span class="mt-3 mb-2 font-semibold">Plantation Name</span>
    <span class="font-semibold">{{ plantationData.plantation_name }}</span>
  </div>

  <div class="flex flex-column mx-4">
    <div class="flex flex-column mb-4">
      <span class="font-semibold mb-2">Risk Type</span>
      <p-multiSelect
        class="mb-2"
        [options]="riskTypeOptions"
        [(ngModel)]="selectedRisksStep2"
        placeholder="Select Cities"
        optionLabel="label"
        optionValue="value"
        display="chip"
        [showClear]="true"
        [appendTo]="'body'"
      />
    </div>
    <div class="flex flex-column">
      <span class="font-semibold mb-2">Upload file</span>
      <app-upload-file-control
        [multiple]="true"
        [acceptedFileTypes]="['.jpeg', '.jpg']"
        (filesSelected)="onFilesSelected($event)"
      />
    </div>

    <div class="flex flex-column">
      <span class="font-semibold mb-2">Comments</span>
      <input
        type="text"
        pInputText
        placeholder="Enter comments to your submission"
      />
    </div>
  </div>

  <div class="modal-action-section mt-4">
    <p-button (onClick)="onBack()" styleClass="p-button-outlined mt-4"
      >Back</p-button
    >
    <p-button (onClick)="onProceedSubmission()" styleClass="mt-4 mx-4"
      >Proceed Submission</p-button
    >
  </div>
</ng-template>

<ng-template #step3>
  <div class="flex flex-column mx-4 my-3">
    <span class="mt-3 font-semibold"
      >This is to confirm your risk verification submission for your plantation:
      Kebun XYZ. Are you sure you want to proceed?</span
    >
    <span class="font-semibold mb-6">{{ plantationData.plantation_name }}</span>
    <span class="font-semibold">Are you sure you want to proceed?</span>
  </div>
  <div class="modal-action-section mt-4">
    <p-button (onClick)="onBack()" styleClass="p-button-outlined mt-4"
      >Back to previous</p-button
    >
    <p-button (onClick)="onProceedSubmission()" styleClass="mt-4 mx-4"
      >Yes, proceed</p-button
    >
  </div>
</ng-template>
