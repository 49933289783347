<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="grid">
  <div class="col-12">
    <!-- TODO: refactor - too many conditions! -->
    <ng-container
      *ngIf="
        ((crdStateService.pagedPlantationList$ | async) ===
          INITIAL_PAGED_STATE ||
          (eventStateService.isDashboardTableLoading$ | async)) &&
        !(eventStateService.hasError$ | async) &&
        selectedTab === PLANTATION_TAB &&
        !isSiLinking
      "
    >
      <ng-template *ngTemplateOutlet="controlsLoading"></ng-template>
    </ng-container>
    <p-card
      *ngIf="selectedTab === PLANTATION_TAB && !isSiLinking"
      [class.hidden]="
        (crdStateService.pagedPlantationList$ | async) ===
          INITIAL_PAGED_STATE ||
        (eventStateService.isDashboardTableLoading$ | async)
      "
    >
      <div class="flex justify-content-between">
        <div class="flex flex-column p-2 mr-3 selected-plantations-length">
          <span
            >{{ selectedItems.length }}
            {{ 'DASHBOARD.SELECTED' | transloco }}</span
          >
        </div>

        <div class="flex justify-content-end selection-controls">
          <p-button
            [disabled]="isListControlsDisabled"
            styleClass="p-button-text m-1"
            [style]="{ 'min-width': '7rem' }"
            (onClick)="onClearAllClicked()"
          >
            {{ 'DASHBOARD.CLEAR_ALL' | transloco }}</p-button
          >
          <p-button
            [disabled]="isListControlsDisabled"
            styleClass="p-button m-1"
            [style]="{ 'min-width': '9rem' }"
            (onClick)="onShowMap()"
          >
            <i class="pi pi-map mr-2"></i>
            {{ 'DASHBOARD.SHOW_MAP' | transloco }}</p-button
          >
          <div class="mx-1"></div>

          <p-button
            styleClass="p-button-outlined m-1"
            (click)="op.toggle($event)"
          >
            <i class="pi pi-download mr-2"></i>
            {{ 'DASHBOARD.DOWNLOAD' | transloco }}</p-button
          >
          <p-overlayPanel #op>
            <div class="flex flex-column download-options">
              <div
                *ngFor="let option of downloadOptionProps; last as last"
                class="flex align-items-center download-option"
                [ngClass]="{
                  disabled:
                    (option.loadingState | async) ||
                    (option.isAlwaysEnabled ? false : isListControlsDisabled),
                }"
                (click)="onDownloadClicked(option.downloadOption)"
              >
                <div>
                  <i class="pi pi-download mr-3"></i>
                </div>
                <div class="mr-2">
                  <span> {{ option.label }}</span>
                </div>
                <ng-container *ngIf="!!(option.loadingState | async)">
                  <app-progress-spinner></app-progress-spinner>
                </ng-container>
              </div>
            </div>
          </p-overlayPanel>
        </div>
      </div>
      <div
        class="flex chips-container align-items-center mt-2"
        *ngIf="selectedItemNames.length"
      >
        <p-chips
          (keypress)="(false)"
          (onRemove)="onSelectedItemRemove($event.value)"
          [(ngModel)]="selectedItemNames"
        ></p-chips>
      </div>
    </p-card>
  </div>
</div>
<ng-container *ngIf="isSiLinking">
  <div class="flex mt-2 mb-4">
    <!-- TODO: dropdown for si linking options -->
    <!-- <div>
      <ng-content select="[datasetAutocomplete]"></ng-content>
    </div> -->
    <div class="mr-3">
      <ng-content select="[datasetAutocomplete]"></ng-content>
    </div>
    <div>
      <ng-template *ngTemplateOutlet="favoriteFiltersDropdown"></ng-template>
    </div>
  </div>
</ng-container>
<div class="grid">
  <div
    class="col-12 plantation-table"
    [ngClass]="{ 'plantation-table-si-linking': isSiLinking }"
  >
    <ng-container
      *ngIf="
        (crdStateService.pagedPlantationList$ | async) ===
          INITIAL_PAGED_STATE && !(eventStateService.hasError$ | async)
      "
    >
      <ng-template *ngTemplateOutlet="tableLoading"></ng-template>
    </ng-container>
    <p-card
      [class.hidden]="
        (crdStateService.pagedPlantationList$ | async) === INITIAL_PAGED_STATE
      "
    >
      <p-progressBar
        *ngIf="(eventStateService.isDashboardTableLoading$ | async)!"
        mode="indeterminate"
      ></p-progressBar>
      <p-table
        #dt
        [value]="rowData"
        [paginator]="true"
        [rows]="10"
        [(selection)]="selectedItems"
        [columns]="columns"
        (onLazyLoad)="lazyLoadData($event)"
        (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
        [lazy]="true"
        editMode="row"
        [dataKey]="tableDataKey"
        [stateStorage]="'session'"
        [stateKey]="isPlantationTab ? 'dashboardTableState' : undefined"
        (onStateRestore)="onTableStateRestore($event)"
        [tableStyle]="tableStyle"
        [selectionPageOnly]="true"
        (selectionChange)="onSelectionChange($event)"
        [selectionMode]="'multiple'"
        [showCurrentPageReport]="true"
        (onFilter)="onFilterChange()"
        [loading]="(eventStateService.isDashboardTableLoading$ | async)!"
        [totalRecords]="totalCount"
        currentPageReportTemplate="{{
          'DASHBOARD.SHOWING_TABLE_ENTRIES_COUNT'
            | transloco
              : {
                  first: '{first}',
                  last: '{last}',
                  totalRecords: '{totalRecords}'
                }
        }}"
        [globalFilterFields]="globalFilterFields"
        [rowsPerPageOptions]="isSiLinking ? [10, 30, 100, 1000] : [10, 30, 100]"
        (onPage)="onPageChange($event)"
      >
        <ng-template pTemplate="caption">
          <div class="grid p-2 mt-0">
            <div
              class="col-12 pb-0 flex align-items-center justify-content-between"
            >
              <div class="flex table-header">
                <span class="table-label mx-1">
                  {{ 'DASHBOARD.LIST_OF_PLANTATIONS' | transloco }}</span
                >
              </div>
              <div *ngIf="selectedTab === PLANTATION_TAB">
                <span class="p-input-icon-left mx-2">
                  <i class="pi pi-search"></i>
                  <input
                    type="text"
                    [(ngModel)]="globalSearchInput"
                    (input)="applyFilterGlobal($event, 'contains')"
                    class="search-bar"
                    pInputText
                    placeholder="{{ 'DASHBOARD.SEARCH' | transloco }}"
                  />
                </span>
                <ng-container *ngIf="!isSiLinking">
                  <ng-template *ngTemplateOutlet="favoriteFiltersDropdown" />
                  <p-button
                    (onClick)="onAddFavoriteFilterClicked()"
                    styleClass="p-button-outlined mx-2"
                    [disabled]="
                      !enableAddFavoriteFilter ||
                      !!selectedFavoriteFilter?.filter_name
                    "
                  >
                    <i class="pi pi-plus mr-2"></i>
                    <span> {{ 'DASHBOARD.FAVORITE_FILTER' | transloco }} </span>
                  </p-button>
                  <p-button
                    (onClick)="onUploadClicked()"
                    [disabled]="selectedItems.length ? true : false"
                    styleClass="ml-2"
                  >
                    <i class="pi pi-upload mr-2"></i>
                    <span>
                      {{ 'DASHBOARD.UPLOAD' | transloco }}
                    </span>
                  </p-button>
                </ng-container>
              </div>
            </div>
            <div class="w-full divider-container">
              <p-divider type="solid" />
            </div>
            <div class="col-12 py-0 mb-2-5">
              <p-tabView
                [activeIndex]="selectedTab"
                (activeIndexChange)="onTabChange($event)"
              >
                <p-tabPanel header="{{ 'DASHBOARD.PLANTATION' | transloco }}">
                </p-tabPanel>
                <p-tabPanel
                  [disabled]="isSiLinking"
                  header="{{ 'DASHBOARD.QUESTIONNAIRE' | transloco }}"
                >
                </p-tabPanel>
              </p-tabView>
            </div>
            <div
              class="col-12 py-0 mb-2-5 font-normal"
              *ngIf="selectedTab === QUESTIONNAIRE_TAB"
            >
              {{
                'DASHBOARD.QUESTIONNAIRE_COLUMNS.DISCLAIMER_QUESTIONNAIRE'
                  | transloco
              }}
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th class="checkbox-cell" *ngIf="selectedTab === PLANTATION_TAB">
              <p-tableHeaderCheckbox #headerCheckbox></p-tableHeaderCheckbox>
            </th>
            <ng-container *ngFor="let column of columns; index as i">
              <th
                [pSortableColumn]="column.sortable ? column.field : ''"
                [style]="column?.style"
              >
                <div class="th-container">
                  <label>
                    {{ column.displayName | transloco }}
                  </label>

                  <p-sortIcon
                    *ngIf="column.sortable"
                    [field]="column.field"
                  ></p-sortIcon>
                  <p-columnFilter
                    *ngIf="column.filter"
                    [field]="column.field"
                    display="menu"
                    [showMatchModes]="false"
                    [showOperator]="false"
                    [showAddButton]="false"
                    [showApplyButton]="false"
                    [showClearButton]="false"
                  >
                    <ng-template
                      pTemplate="filter"
                      let-value
                      let-filter="filterCallback"
                    >
                      <p-multiSelect
                        #tableFilter
                        [ngModel]="value"
                        [options]="getFilterOptions(column.field)"
                        placeholder="{{ 'DASHBOARD.ANY' | transloco }}"
                        optionLabel="label"
                        optionValue="value"
                      >
                        <ng-template let-option pTemplate="item">
                          <div class="flex">
                            <app-risk-icon
                              *ngIf="option?.iconColor"
                              [label]="option.label"
                              [iconColor]="option.iconColor"
                            ></app-risk-icon>
                            <span
                              *ngIf="option?.type === 'geojson'"
                              tooltipPosition="bottom"
                              class="material-symbols-outlined mr-1 geojson-type-icon"
                            >
                              {{ getGeoJsonIconProps(option.value).icon }}
                            </span>
                            <span class="ml-1 mt-1">{{
                              option.label || '-'
                            }}</span>
                          </div>
                        </ng-template>
                      </p-multiSelect>
                      <div class="filter-actions flex justify-content-between">
                        <p-button
                          (onClick)="filter([]); onFilterControlClicked()"
                          styleClass="p-button-outlined p-button-sm"
                        >
                          <span class="p-button-label">{{
                            'DASHBOARD.CLEAR' | transloco
                          }}</span></p-button
                        >
                        <p-button
                          (onClick)="
                            filter(tableFilter.value); onFilterControlClicked()
                          "
                          styleClass="p-button p-button-sm"
                        >
                          <span class="p-button-label">{{
                            'DASHBOARD.APPLY' | transloco
                          }}</span></p-button
                        >
                      </div>
                    </ng-template>
                  </p-columnFilter>
                </div>
              </th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-editing="editing"
          let-index="rowIndex"
        >
          <tr [pEditableRow]="rowData">
            <td class="checkbox-cell" *ngIf="selectedTab === PLANTATION_TAB">
              <p-checkbox
                (ngModelChange)="onSelectionChange($event)"
                [value]="rowData"
                name="submissions"
                [(ngModel)]="selectedItems"
              ></p-checkbox>
            </td>
            <ng-container *ngFor="let column of columns; index as i">
              <td style="height: 1rem" *ngIf="column.field !== 'actions'">
                <ng-container *ngIf="!column?.editable">
                  <ng-container *ngIf="selectedTab === PLANTATION_TAB">
                    <label *ngIf="column.field !== 'risks'">{{
                      rowData[column.field] || '-'
                    }}</label>

                    <ng-container *ngIf="column.field === 'risks'">
                      <ng-container
                        *ngIf="
                          rowData['risks']?.length &&
                            rowData['risks'][0] !== riskTypes.NO_RISKS;
                          else noRisk
                        "
                      >
                        <ng-container *ngFor="let risk of rowData['risks']">
                          <app-risk-icon
                            [iconColor]="risk?.color"
                            [label]="risk?.display_name"
                            [marginClass]="'mr-2'"
                          ></app-risk-icon>
                        </ng-container>
                      </ng-container>
                      <ng-template #noRisk><span>-</span></ng-template>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="selectedTab === QUESTIONNAIRE_TAB">
                    <button
                      *ngIf="!column.isText"
                      pButton
                      pRipple
                      type="button"
                      class="p-button-text table-action cursor-auto"
                    >
                      <i
                        class="pi pi-circle-on table-pi-icon"
                        [style.color]="
                          rowData['survey_risks_updated'][
                            column.field + '_color'
                          ]
                        "
                      ></i>
                    </button>

                    <ng-container *ngIf="!!column.isText">{{
                      rowData[column.field]
                    }}</ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="column?.editable">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        pInputText
                        type="text"
                        [(ngModel)]="rowData[column.field]"
                        required
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div class="flex align-items-center">
                        <label
                          *ngIf="
                            !(
                              column.field === 'area_calculated' ||
                              column.field === 'plantation_name'
                            )
                          "
                          >{{ rowData[column.field] }}</label
                        >
                        <label *ngIf="column.field === 'area_calculated'"
                          >{{ rowData[column.field] || '-' }}
                          <span *ngIf="rowData[column.field]">ha</span></label
                        >
                        <ng-container
                          *ngIf="column.field === 'plantation_name'"
                        >
                          <span
                            [pTooltip]="
                              getGeoJsonIconProps(rowData['_geo_type']).label
                            "
                            tooltipPosition="bottom"
                            class="material-symbols-outlined mr-1 geojson-type-icon"
                          >
                            {{ getGeoJsonIconProps(rowData['_geo_type']).icon }}
                          </span>
                          <label>{{ rowData[column.field] || '-' }}</label>
                        </ng-container>
                      </div>
                    </ng-template>
                  </p-cellEditor>
                </ng-container>
              </td>
            </ng-container>
            <td
              style="height: 1rem"
              *ngIf="selectedTab === PLANTATION_TAB && !isSiLinking"
            >
              <!-- TODO: uncomment edit when BE is ready -->
              <!-- <button
                  *ngIf="!editing && rowData.data_source === 'file_upload'"
                  pButton
                  pRipple
                  type="button"
                  pInitEditableRow
                  (click)="onRowEditInit(rowData, index)"
                  class="p-button-text table-action"
                >
                  <span class="material-symbols-outlined edit-icon"> edit </span>
                </button> -->
              <button
                *ngIf="!editing"
                pButton
                pRipple
                type="button"
                class="p-button-text table-action"
              >
                <span
                  class="material-symbols-outlined delete-icon"
                  (click)="onDelete(rowData)"
                >
                  delete
                </span>
              </button>
              <!-- <button
                *ngIf="!editing"
                pButton
                pRipple
                type="button"
                class="p-button-text table-action"
              >
                <span
                  class="material-symbols-outlined"
                  (click)="onRiskVerificationClicked(rowData)"
                >
                  <span class="material-symbols-outlined"> beenhere </span>
                </span>
              </button> -->
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                (click)="onRowEditSave(rowData)"
                class="p-button-text table-action p-button-success"
              >
                <span class="material-symbols-outlined"> done </span>
              </button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                (click)="onRowEditCancel(rowData, index)"
                class="p-button-text table-action p-button-danger"
              >
                <span class="material-symbols-outlined"> close </span>
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="13">
              <span style="color: c6c6d0">{{
                'DASHBOARD.NO_DATA_FOUND' | transloco
              }}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </div>
</div>

<ng-template #tableLoading>
  <p-skeleton width="100%" height="50rem"></p-skeleton>
</ng-template>

<ng-template #controlsLoading>
  <p-skeleton width="100%" height="8rem"></p-skeleton>
</ng-template>

<ng-template #favoriteFiltersDropdown>
  <p-dropdown
    styleClass="mx-2 w-16rem"
    [options]="(crdStateService.favoritePlantationFilters$ | async)!"
    [(ngModel)]="selectedFavoriteFilter"
    optionLabel="filter_name"
    placeholder="{{ 'DASHBOARD.SELECT_FAVORITE_FILTER' | transloco }}"
    [showClear]="true"
    (onChange)="onFavoriteFilterSelectionChange($event)"
  />
</ng-template>
<p-toast position="bottom-center" [life]="5000"></p-toast>
