import { PLANTATION_LIST_TABLE_COLUMNS } from '../constants/table-columns.const';
import { PlantationTableTabsEnum } from '../enums/plantation-table-tabs.enum';
import { ScreenEnum } from '../enums/screens.enum';
import { ColumnDefinition } from './column-definition.model';
import { PagedResponse } from './paged-response.model';

export interface Plantation {
  _id?: string;
  plantation_code: string;
  plantation_name: string;
  area_calculated: string;
  country: string;
  adm_1: string;
  adm_2: string;
  adm_3: string;
  adm_4: string;
  date_created: string;
  data_source: string;
  field_team_code?: string;
  _geo?: string;
  risks?: Risk[];
  isExpanded?: boolean;
  checked?: boolean;
  mapData?: MapData;
  risk_self_overlap_v1_value?: boolean;
  risk_tree_cover_loss_v1_value?: boolean;
  risk_wdpa_v1_value?: boolean;
  has_any_risk_value?: boolean;
  T?: string;
  _docid?: string | null;
  survey_risks?: SurveyRisk[];
  survey_risks_updated?: SurveyRiskUpdated;
}

export interface SurveyRisk {
  framework: string;
  qid: string;
  risk_score: number;
  category: string;
  question: string;
}

export interface SurveyRiskUpdated {
  [key: string]: SurveyRisk | string | undefined;
}

export interface RiskOverlapDetail {
  label: string;
  riskType: string;
  description: string;
  areaCalculatedSum?: number;
  plantationCodeCount?: number;
  color: string;
  areas?: any;
}

export interface PlantationTableFilters {
  [key: string]: {
    label: string;
    value: any;
    iconColor?: string;
    type?: string;
  }[];
}

export interface AdmFilter {
  country: string;
  adm_1: string;
  adm_2: string;
}

export interface DataSourceFilter {
  data_source: string;
}

export interface PlantationNameFilter {
  plantation_code: string;
  plantation_name: string;
}

export interface RiskLayer {
  attribution: string;
  dataurl: string;
  id: string;
  links: string[];
  mapid: string;
  name: string;
  provider_link: string;
  provider_name: string;
  token: string;
  tou: string;
  tou_link: string;
  type: string;
  checked?: boolean;
  color: string;
}

export interface Dataset {
  name: string;
  default_plantations_table: string;
  description: string;
  producerName?: string;
}

export interface TotalLandArea {
  country: string;
  area: number;
  totalCount: number;
  totalYield: number;
}

export interface TotalPlot {
  geometry_type: string;
  count: number;
  label?: string;
  icon?: string;
}

export interface TotalMappingEffort {
  day: string;
  plantationsMapped: number;
  totalArea: number;
  data_source: string;
}

export interface TotalSummary {
  totalPlots: TotalPlot[];
  totalLandAreas: TotalLandArea[];
  totalMappingEfforts: TotalMappingEffort[];
}

// Used in Questionaire table
export interface Risk {
  category: string;
  total_score: number;
  color?: string; // hex color
  display_name?: string;
}

export interface RiskValueAggregate {
  area_calculated_sum: number;
  plantation_code_count: number;
}

export interface RiskProperty {
  name: string;
  display_name: string;
  color: string;
  type: string;
  is_default?: boolean;
  description: string;
}
export interface FilterConditions {
  [key: string]: {
    [key: string]: any[];
  };
}

export interface Filters {
  AND?: FilterConditions;
  OR?: FilterConditions;
}

export interface FavoriteFilter {
  id: number;
  dataset_id: number;
  dataset_name: string;
  filters: Filters;
  filter_name: string;
}

export interface GeometryData {
  plantation_code: string;
  _geo: MapData | string;
}

export interface MapData {
  type: string;
  properties: any[];
  geometry: GeometryProps;
  centerCoordinates?: any;
}

export interface GeometryProps {
  type: string;
  coordinates: any;
}

export interface SiDetails {
  siNumber: string;
  siDetailId: string;
  factoryCode: string;
  contractNumber: string;
  company: Company;
}

export interface Company {
  type: string;
  name: string;
}

export interface CrdState {
  plantation: Plantation;
  selectedPlantationList: Plantation[];
  checkedSelectedPlantationList?: Plantation[];
  pagedPlantationList: PagedResponse<Plantation>;
  pagedRiskValues: PagedResponse<any>;
  pagedRiskValuesModal: PagedResponse<any>;
  // TODO: update type when Questionnaire interface updated
  pagedQuestionnaireList: PagedResponse<any>;
  selectedQuestionnaireList: any[];
  favoritePlantationFilters?: FavoriteFilter[];
  plantationListFilters?: PlantationTableFilters;
  questionnaireListFilters?: PlantationTableFilters;
  selectedPlantationFilters?: PlantationTableFilters;
  riskOverlapDetails?: RiskOverlapDetail[];
  riskLayers: RiskLayer[];
  geometryData: GeometryData[];
  selfOverlapGeometryData: GeometryData[];
  mapStyle: string;
  currentScreen: ScreenEnum;
  geometryDataExport: Plantation[];
  datasets?: Dataset[];
  selectedDataset?: Dataset;
  selectedPeriod?: string;
  // TODO: update type
  parsedFileData?: any;
  parsedFileErrors?: any;
  uploadFileMappingColumns?: ColumnDefinition[];
  totalSummary?: TotalSummary;
  riskValueAggregates?: RiskValueAggregate[];
  riskProperties?: RiskProperty[];
  selectedTab?: PlantationTableTabsEnum;
  siDetails?: SiDetails | null;
  tableColumns: ColumnDefinition[];
}

// TODO: need to align with BE to use just page/pageNumber
export const INITIAL_TABLE_PARAMS = { page: 1, pageNumber: 1, pageSize: 10 };

export const INITIAL_PAGED_STATE: PagedResponse<Plantation> = {
  count: 0,
  next: '',
  previous: '',
  num_pages: 0,
  page: 0,
  page_size: 0,
  results: []
};

export const INITIAL_SELECTED_PERIOD = '2022-01-01';

export const INITIAL_CRD_STATE: CrdState = {
  pagedPlantationList: INITIAL_PAGED_STATE,
  pagedQuestionnaireList: INITIAL_PAGED_STATE,
  selectedQuestionnaireList: [],
  pagedRiskValues: INITIAL_PAGED_STATE,
  pagedRiskValuesModal: INITIAL_PAGED_STATE,
  riskLayers: [],
  geometryData: [],
  selfOverlapGeometryData: [],
  mapStyle: '',
  selectedPlantationList: [],
  plantation: {
    plantation_code: '',
    plantation_name: '',
    area_calculated: '',
    country: '',
    adm_1: '',
    adm_2: '',
    adm_3: '',
    adm_4: '',
    date_created: '',
    data_source: '',
    field_team_code: '',
    risks: []
  },
  currentScreen: ScreenEnum.DASHBOARD,
  geometryDataExport: [],
  tableColumns: PLANTATION_LIST_TABLE_COLUMNS
};
