import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { RiskIconComponent } from 'src/app/shared/risk-icon/risk-icon.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { CrdStateService } from 'src/app/services/state-service/crd-state.service';
import {
  INITIAL_SELECTED_PERIOD,
  Plantation
} from 'src/app/models/crd-state.interface';
import { UtilityService } from 'src/app/services/utility.service';
import { Subject, takeUntil } from 'rxjs';
import { RiskTypesEnum } from 'src/app/enums/risk-types.enum';
import { FormsModule } from '@angular/forms';
import { FilterService } from 'primeng/api';

@Component({
  selector: 'app-si-linking-summary-page',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    RiskIconComponent,
    TranslocoPipe,
    FormsModule
  ],
  templateUrl: './si-linking-summary-page.component.html',
  styleUrls: ['./si-linking-summary-page.component.scss']
})
export class SiLinkingSummaryPageComponent implements OnInit, OnDestroy {
  @Input({ required: true }) columns!: ColumnDefinition[];
  @Input() pageSize = 10;
  @Input() selectedItems: any = [];
  @Output() tableLoading = new EventEmitter<boolean>();

  destroyed$ = new Subject<void>();
  tableDataKey = 'plantation_code';
  riskTypes = RiskTypesEnum;
  isTableLoading = true;
  filterOptions: any[] = [];

  constructor(
    private eventStateService: EventStateService,
    public crdStateService: CrdStateService,
    public utilityService: UtilityService,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.assignGeometryData();
    this.assignFilterOptions();
    this.checkIsTableLoading();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  checkIsTableLoading() {
    this.eventStateService.isGeometryDataLoading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isLoading) => {
        this.isTableLoading = isLoading;
        this.tableLoading.emit(this.isTableLoading);
      });
  }

  assignGeometryData() {
    const plantationCodes = this.utilityService.getPlantationCodesArray(
      this.selectedItems,
      'plantation_code'
    );
    this.crdStateService.getGeometryData(
      {
        plantationCodes: plantationCodes,
        period: INITIAL_SELECTED_PERIOD
      },
      true
    );
  }

  getFilterOptions(columnField: string) {
    return this.filterOptions.find((option) => option.field === columnField)
      ?.options;
  }

  initializeCustomRiskFilter() {
    this.filterService.register(
      'risk-filter',
      (value: any[], filter: string[]): boolean => {
        if (filter?.length) {
          const riskTypes = value?.map((risk) => risk.type);
          return filter?.some((type) => riskTypes?.includes(type));
        }

        return true;
      }
    );
  }

  assignFilterOptions() {
    this.initializeCustomRiskFilter();
    this.filterOptions = [
      {
        field: 'plantation_name',
        options: this.getFilterOptionsPerField('plantation_name')
      },
      {
        field: 'risks',
        options: this.getFilterOptionsPerField('risks')
      },
      {
        field: 'country',
        options: this.getFilterOptionsPerField('country')
      },
      {
        field: 'adm_1',
        options: this.getFilterOptionsPerField('adm_1')
      },
      {
        field: 'adm_2',
        options: this.getFilterOptionsPerField('adm_2')
      },
      {
        field: 'adm_3',
        options: this.getFilterOptionsPerField('adm_3')
      },
      {
        field: 'adm_4',
        options: this.getFilterOptionsPerField('adm_4')
      },
      {
        field: 'data_source',
        options: this.getFilterOptionsPerField('data_source')
      }
    ];
  }

  getFilterOptionsPerField(field: keyof Plantation) {
    // if (field === 'risks') {
    //   const riskOptions: any[] = [
    //     // {
    //     //   label: this.translocoService.translate('RISK_TYPES.NO_RISKS'),
    //     //   value: RiskTypesEnum.NO_RISKS,
    //     //   hasRiskIcon: false
    //     // },
    //     // {
    //     //   label: this.translocoService.translate('RISK_TYPES.TREE_COVER_LOSS'),
    //     //   value: RiskTypesEnum.TREE_COVER_LOSS,
    //     //   hasRiskIcon: true
    //     // },
    //     // {
    //     //   label: this.translocoService.translate('RISK_TYPES.WDPA'),
    //     //   value: RiskTypesEnum.WDPA,
    //     //   hasRiskIcon: true
    //     // },
    //     // {
    //     //   label: this.translocoService.translate('RISK_TYPES.SELF_OVERLAP'),
    //     //   value: RiskTypesEnum.SELF_OVERLAP,
    //     //   hasRiskIcon: true
    //     // }
    //   ];

    //   return riskOptions;
    // }

    const option = Array.from(
      new Set(this.selectedItems.map((row: any) => row[field]))
    ).map((option) => ({ label: option, value: option }));
    return option;
  }
}
