import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { TranslocoPipe } from '@jsverse/transloco';
import { RISK_VERIFICATION_COLUMNS } from 'src/app/constants/table-columns.const';
import { FormsModule } from '@angular/forms';
import { UploadFileControlComponent } from 'src/app/shared/upload-file-control/upload-file-control.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-risk-verification-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    TranslocoPipe,
    FormsModule,
    UploadFileControlComponent
  ],
  templateUrl: './risk-verification-modal.component.html',
  styleUrls: ['./risk-verification-modal.component.scss']
})
export class RiskVerificationModalComponent {
  step = 1;
  columns = RISK_VERIFICATION_COLUMNS;

  // TODO: update types
  rowData: any[] = [
    {
      id: 1,
      risk_name: 'Tree Cover Loss',
      risk_type: 'Geospatial',
      risk_status: 'High',
      file_type: 'pdf',
      updated_date: '1/1/2024'
    },
    {
      id: 2,
      risk_name: 'Plantation Overlap',
      risk_type: 'Geospatial',
      risk_status: 'High',
      file_type: 'pdf',
      updated_date: '1/1/2024'
    }
  ];

  riskTypeOptions = [
    {
      value: 'Tree Cover Loss',
      label: 'Tree Cover Loss'
    },
    {
      value: 'Plantation Overlap',
      label: 'Plantation Overlap'
    }
  ];

  selectedRisksStep1: any = [];
  selectedRisksStep2: any = [];

  get plantationData() {
    return this.dialogConfig.data;
  }

  constructor(private dialogConfig: DynamicDialogConfig) {
    console.log(this.plantationData);
  }

  onClearAll() {
    this.selectedRisksStep1 = [];
  }

  onProceedRiskVerification() {
    this.step = 2;
  }

  onFilesSelected(files: File[] | null) {
    console.log(files);
  }

  onBack() {
    this.step = --this.step;
  }

  onProceedSubmission() {
    this.step = 3;
  }
}
